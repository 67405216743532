import { Title, TitleProps } from '@mantine/core';
import styles from '~/components/Typograph/Typograph.module.css';

type Props = TitleProps;

function Subtitle1(props: Props) {
  return (
    <Title
      fz={16}
      fs="normal"
      fw={600}
      className={styles.subtitle1}
      {...props}
    />
  );
}

export default Subtitle1;
